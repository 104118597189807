import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-rapport-detail',
	templateUrl: './rapport-detail.component.html',
	styleUrls: ['./rapport-detail.component.css']
})
export class RapportDetailComponent implements OnInit {

	rapp_info:any;
	part_id:any = this.tokenStore.getUser().id; 

	constructor(private userService: UserService, 
		private tokenStore:TokenStorageService,
		private router:Router) { }

	ngOnInit(): void {

		this.rapp_dette(this.part_id);
	}

	//recup des dettes du debiteur
	rapp_dette(id:number){
		this.userService.getRappDette(id).subscribe((debt : any) => {
			this.rapp_info = debt;
			console.log (this.rapp_info);
		}
		)}

	}
