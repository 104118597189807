import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-board-user',
  templateUrl: './board-user.component.html',
  styleUrls: ['./board-user.component.css']
})
export class BoardUserComponent implements OnInit {
  searchText :any;
  partenaire:any;
  debs: any;
  part_id:any = this.tokenStore.getUser().id; 

  displayedColumns: string[] = ['societe/ets', 'gerant', 'email', 'telephone', 'ville'];
  //dataSource:any;

  constructor(private userService: UserService, 
    private tokenStore:TokenStorageService,
    private router:Router) { }

  ngOnInit(): void {

    this.partenaire = this.tokenStore.getUser();
    this.debsPart();
  }

  //recuperation debiteurs
  debsPart(){
    this.userService.getDebs(this.part_id).subscribe((debs : any) => {
      this.debs = debs;
      //this.dataSource = debs;
      console.log (this.debs);
    }
    )}

    //Details de la dette du debiteurs
    detail_deb(deb_id:number){
      this.router.navigate(['/info_deb'], { queryParams: { id:deb_id } })
    }

    //Rapport de la dette du debiteurs
    rapport_detail(deb_id:number){
      this.router.navigate(['/rapport-detail'], { queryParams: { id:deb_id } })
    }

    
  }

