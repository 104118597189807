import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { InfoDebiteurComponent } from './info-debiteur/info-debiteur.component';
import { RecuDebiteurComponent } from './recu-debiteur/recu-debiteur.component';
//import { RapportComponent } from './rapport/rapport.component';
import {RapportDetailComponent} from './rapport-detail/rapport-detail.component';

const routes: Routes = [
{ path: 'home', component: HomeComponent },
{ path: 'login', component: LoginComponent },
{ path: 'profile', component: ProfileComponent },
{ path: 'user', component: BoardUserComponent },
{ path: 'info_deb', component: InfoDebiteurComponent },
{ path: 'info_deb_recu', component: RecuDebiteurComponent },
//{path: 'rapport', component:RapportComponent},
{path: 'rapport-detail', component:RapportDetailComponent},

{ path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
