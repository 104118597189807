<div class="container" *ngIf="!isLoggedIn">
	<header class="jumbotron">
		<h2>PARTENAIRE</h2>
	</header>
</div>

<div class="container" *ngIf="isLoggedIn">
	<header class="jumbotron">
		<h2>Bienvenu {{partenaire.nom}}</h2>
	</header>
</div>
