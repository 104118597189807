import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-recu-debiteur',
	templateUrl: './recu-debiteur.component.html',
	styleUrls: ['./recu-debiteur.component.css']
})
export class RecuDebiteurComponent implements OnInit {

	constructor(private userService: UserService, 
		private route:ActivatedRoute,
		) { }

	id:any;
	debt_info_recus:any

	ngOnInit(): void {
		this.route.queryParams
		.subscribe(params => { 
			this.id = params.id;
			console.log(this.id);
		})

		this.info_recu(this.id);

	}

	info_recu(id:number){ 
		this.userService.getInfoRecu(id).subscribe((recus : any) => {
			this.debt_info_recus = recus;
			console.log (this.debt_info_recus);
		})

	}
}
