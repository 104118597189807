	<div class="row" style="margin-top: 5%">
		
		<div class="table-responsive">
			<table id="example" class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
				<thead class="thead-dark">
					<tr>
						<th scope="col">Bordereau</th>
						<th scope="col">Montant</th>
						<th scope="col">Date</th>
						<th scope="col">Mode de payement</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of debt_info_recus">
						<th scope="row">{{item.bordereau}}</th>
						<td>{{item.montant}} FCFA</td>
						<td>{{item.date | date:'mediumDate'}}</td>
						<td>{{item.mode}}</td>


					</tr>

				</tbody>
			</table>
		</div>

		<div class="container">
			<a href="/user" class="btn btn-primary">  Retour </a>
		</div>

	</div>