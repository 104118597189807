import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
	selector: 'app-info-debiteur',
	templateUrl: './info-debiteur.component.html',
	styleUrls: ['./info-debiteur.component.css']
})
export class InfoDebiteurComponent implements OnInit {
	//id:any;
	debt_info:any;
	id:any;
	debt_info_recus:any


	constructor(private userService: UserService, 
		private route:ActivatedRoute,
		private router:Router) { }

	ngOnInit(): void {
		this.route.queryParams
		.subscribe(params => { 
			this.id = params;
			console.log(this.id);
		}
		);

		this.info_dette(this.id.id);
		//this.info_recu(this.debt_info.id);
	}

	//recuperation des dettes du debiteur
	info_dette(id:number){
		this.userService.getInfoDette(id).subscribe((debt : any) => {
			this.debt_info = debt;
			console.log (this.debt_info);
		}
		)}

		//recus du debiteur
		/*info_deb_recu(recu_id:number){
			this.router.navigate(['/info_deb_recu'], { queryParams: { id:recu_id } })
		}*/

		info_recu(id:number){ 
			this.userService.getInfoRecu(id).subscribe((recus : any) => {
				this.debt_info_recus = recus;
				console.log (this.debt_info_recus);
			})
		}

	}
