
<header class="jumbotron">
	<h3>
		RECAPITULATIF
	</h3>
</header>


<div class="row" style="margin-top: 5%">
	<div class="table-responsive">
		<table  class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
			<thead class="thead-dark">
				<tr>
					<th scope="col">Désignations</th>
					<th scope="col">Valeurs</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>Montant estimatif de la creance:</td>
					<td>{{rapp_info.montant_creance}} FCFA</td>
				</tr>
				<tr>
					<td>Nombre de dossiers transmis:</td>
					<td>{{rapp_info.nbre_dossier_transmi}}</td>
				</tr>
				<tr>
					<td>Nombre de dossiers actifs:</td>
					<td>{{rapp_info.nbre_dossier_actif}}</td>
				</tr>
				<tr>
					<td>Nombre de dossiers localisés:</td>
					<td>{{rapp_info.nbre_dossier_localiser}}</td>
				</tr>
				<tr>
					<td>Nombre d'entretien physique:</td>
					<td>{{rapp_info.entr_physiq}}</td>
				</tr>
				<tr>
					<td>Nombre de transmissions de courriers:</td>
					<td>{{rapp_info.trans_courier}}</td>
				</tr>
				<tr>
					<td>Nombre de négociations en cours:</td>
					<td>{{rapp_info.negoc_en_cours}}</td>
				</tr>
				<tr>
					<td>Nombre de protocoles signés:</td>
					<td>{{rapp_info.protocol_signe}}</td>
				</tr>
				<tr>
					<td>Nombre de dossiers en cours de payement:</td>
					<td>{{rapp_info.nbre_dossier_payement}}</td>
				</tr><tr>
					<td>Nombre d'échanges télephoniques:</td>
					<td>{{rapp_info.echange_tel}}</td>
				</tr>
				<tr>
					<td>Nombre d'échanges mails:</td>
					<td>{{rapp_info.echange_email}}</td>
				</tr>
				<tr>
					<td>Appréciation débiteur:</td>
					<td>{{rapp_info.commentaire}}</td>
				</tr>
			</tbody>
		</table>
		<div class="container">
			<a href="../user" class="btn btn-primary">Retour</a>
		</div>
	</div>


