
<div class="row" style="margin-top: 5%">


	<div class="table-responsive">
		<table id="example" class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
			<thead class="thead-dark">
				<tr>
					<th scope="col">Intitulé</th>
					<th scope="col">M.réclamé</th>
					<th scope="col">M.reconnu</th>
					<th scope="col">M.versé</th>
					<th scope="col">Solde</th>
					<th scope="col">Dernier versement</th>
					<th scope="col">Date d'écheance</th>
					<th scope="col">Observations</th>
					<th></th>
				</tr>
			</thead>
			<tbody>

				<tr *ngFor= "let item of debt_info">
					<th scope="row">{{item.intitule}}</th>
					<td>{{item.montant_reclame | number}} FCFA</td>
					<td>{{item.montant_reconnu | number}} FCFA</td>
					<td>{{item.montant_verse | number}} FCFA</td>
					<td>{{(item.montant_reconnu - item.montant_verse) | number}} FCFA</td>
					<td>{{item.dernier_versement | date:'mediumDate'}}</td>
					<td>{{item.date_echeance | date:'mediumDate'}}</td>
					<td>{{item.observation}}</td>
					<!--<td> <button class="btn btn-primary" (click)=info_recu(item.id)>Recus</button> </td>-->
				</tr>

			</tbody>
		</table>
	</div>

	

	<div class="table-responsive" style="margin-top: 5%">
		<table id="example" class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
			<thead class="thead-dark">
				<tr>
					<th scope="col">Bordereau</th>
					<th scope="col">Montant</th>
					<th scope="col">Date</th>
					<th scope="col">Mode de payement</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of debt_info_recus">
					<th scope="row">{{item.bordereau}}</th>
					<td>{{item.montant | number}} FCFA</td>
					<td>{{item.date | date:'mediumDate'}}</td>
					<td>{{item.mode}}</td>


				</tr>

			</tbody>
		</table>
	</div>

	<div class="container">
		<a href="/user" class="btn btn-primary">  Retour </a>
	</div>

</div>