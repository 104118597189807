<div id="app">


  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="home" routerLinkActive="active-link"> ARCREANCES  </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">

      <ul class="navbar-nav  mr-auto" *ngIf="isLoggedIn" >
          <!--<li class="nav-item">
            <a class="nav-link"  routerLink="home">Accueil</a>
          </li>-->
          <li class="nav-item">
            <a class="nav-link"  routerLink="user" routerLinkActive="active-link">ETATS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  routerLink="rapport-detail" routerLinkActive="active-link">RECAPITULATIF</a>
          </li>
        </ul>

        <ul class="navbar-nav" *ngIf="!isLoggedIn" routerLinkActive="active-link">
          <li class="nav-item">
            <a class="nav-link"  routerLink="login">CONNEXION</a>
          </li>
        </ul>

        <ul class="navbar-nav" *ngIf="isLoggedIn">
          <li class="nav-item">
            <a href="/home" class="nav-link" (click)="logout()">DECONNEXION</a>
          </li>
        </ul>

      </div>
    </nav>



    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
