
<div class="row" style="margin-top: 5%">


	<div class="table-responsive" style="margin-bottom: 3%">
		<table  class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
			<thead class="thead-dark">
				<tr>
					<th scope="col">Nom</th>
					<th scope="col">Email</th>
					<th scope="col">Secteur d'activité</th>
					<th scope="col">Ville</th>
					<th scope="col">Telephone</th>

				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{{partenaire.nom}}</td>
					<td>{{partenaire.email}}</td>
					<td>{{partenaire.secteur}}</td>
					<td>{{partenaire.ville}}</td>
					<td>{{partenaire.telephone}}</td>

				</tr>

			</tbody>
		</table>
	</div>


	<h3>DEBITEURS</h3>

	

	<div class="input-group" style="margin-top: 3%">
		<div class="form-outline">
			<input  [(ngModel)]="searchText" placeholder="Recherchez.." type="search" id="form1" class="form-control" />
		</div>



		<div class="table-responsive" style="margin-top: 2%">
			<table id="example" class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
				<thead class="thead-dark">
					<tr>
						<th scope="col">Société / Ets</th>
						<th scope="col">Gérant</th>
						<th scope="col">Email</th>
						<th scope="col">Telephone</th>
						<th scope="col">Ville</th>
						<th scope="col">Info</th>
					</tr>
				</thead>
				<tbody>

					<tr *ngFor="let deb of debs |filter: 'societe_debitrice': searchText;let i = index">
						<th scope="row">{{deb.societe_debitrice}}</th>
						<td>{{deb.gerant}}</td>
						<td>{{deb.email}}</td>
						<td>{{deb.telephone}}</td>
						<td>{{deb.ville}}</td>
						<td> <button class="btn btn-primary" (click)=detail_deb(deb.id)>Détails</button> </td>
					</tr>

				</tbody>
			</table>
		</div>


	</div>